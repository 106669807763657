<template lang='pug'>
  .c-stadistics
    modal(
          name="users"
          :width="1000"
          height="auto"
          :adaptive="true"
          class="c-modal"
        )
      h3 Pacientes Inscritos:
      h1 {{S_INSCRITOS.length}}
      div(style="height:350px;overflow-y:scroll;")
        table
          thead
            th F. Inscripción
            th(style="width:22rem") Nombres y Apellidos
            th Nickname
            th(style="width:7rem") DNI
            th(style="width:7rem") Celular
            th F. Nacimiento
          tbody
          template(v-for="user in S_INSCRITOS")
            tr
              td(style="text-align:center") {{$moment(user.dateInscription).format('YYYY-MM-DD')}}
              td(style="text-align:center") {{user.firstname + ' ' + user.lastname}}
              td(style="text-align:center") {{user.nickname}}
              td(style="text-align:center") {{user.dni}}
              td(style="text-align:center") {{user.phone}}
              td(style="text-align:center") {{user.birthday}}
    modal(
          name="usersNot"
          :width="700"
          height="auto"
          :adaptive="true"
          class="c-modal"
        )
      h3 Pacientes no inscritos:
      h1 {{S_NO_INSCRITOS.length}}
      div(style="height:350px;overflow-y:scroll;")
        table
          thead
            th(style="width:22rem") Nombres y Apellidos
            th(style="width:7rem") DNI
            th(style="width:7rem") Celular
            th F. Nacimiento
          tbody
          template(v-for="user in S_NO_INSCRITOS")
            tr
              td {{user.firstname + ' ' + user.lastname}}
              td(style="text-align:center") {{user.dni}}
              td(style="text-align:center") {{user.phone}}
              td(style="text-align:center") {{user.birthday}}
    .c-stadistics__body
      ul
        li
          .item
            p Total usuarios
            .box
              i.icon-user
              span {{ statistics ? statistics.totalPatients : 0 }}
        li(@click="$modal.show('users')")
          .item
            p Usuarios registrados
            .box
              i.icon-user
              span {{ S_INSCRITOS.length }}
        li(@click="$modal.show('usersNot')")
          .item
            p Usuarios no registrados
            .box
              i.icon-user
              span {{ S_NO_INSCRITOS.length }}
        li
          .item
            p Usuarios en atención
            .box
              i.icon-user
              span {{ statistics ? statistics.sessionsPatients.countInSession : 0 }}
        li
          .item
            p Usuarios atención terminada
            .box
              i.icon-user
              span {{ statistics ? statistics.sessionCompleted.session6 : 0 }}
        li
          .item
            p Sesiones reagendadas
            .box
              i.icon-user
              span {{ statistics ? statistics.sessionsPatients.countReschedules : 0 }}
        li
          .item
            p Agendados en sesion 1
            .box
              i.icon-programas_ico
              span {{ statistics ? statistics.sessionAgended.session1 : 0 }}
        li
          .item
            p Atentidos en sesion 1
            .box
              i.icon-programas_ico
              span {{ statistics ? statistics.sessionCompleted.session1 : 0 }}
        li
          .item
            p Agendados en sesion 2
            .box
              i.icon-programas_ico
              span {{ statistics ? statistics.sessionAgended.session2 : 0 }}
        li
          .item
            p Atentidos en sesion 2
            .box
              i.icon-salas_emo_ico
              span {{ statistics ? statistics.sessionCompleted.session2 : 0 }}
        li
          .item
            p Agendados en sesion 3
            .box
              i.icon-programas_ico
              span {{ statistics ? statistics.sessionAgended.session3 : 0 }}
        li
          .item
            p Atentidos en sesion 3
            .box
              i.icon-salas_ok_ico
              span {{ statistics ? statistics.sessionCompleted.session3 : 0 }}
        li
          .item
            p Agendados en sesion 4
            .box
              i.icon-programas_ico
              span {{ statistics ? statistics.sessionAgended.session4 : 0 }}
        li
          .item
            p Atentidos en sesion 4
            .box
              i.icon-diario_hojas_ico
              span {{ statistics ? statistics.sessionCompleted.session4 : 0 }}
        li
          .item
            p Agendados en sesion 5
            .box
              i.icon-programas_ico
              span {{ statistics ? statistics.sessionAgended.session5 : 0 }}
        li
          .item
            p Atentidos en sesion 5
            .box
              i.icon-salas_ok_ico
              span {{ statistics ? statistics.sessionCompleted.session5 : 0 }}
        li
          .item
            p Agendados en sesion 6
            .box
              i.icon-programas_ico
              span {{ statistics ? statistics.sessionAgended.session6 : 0 }}
        li
          .item
            p Atentidos en sesion 6
            .box
              i.icon-diario_hojas_ico
              span {{ statistics ? statistics.sessionCompleted.session6 : 0 }}
</template>
<script>
import { mapActions, mapState } from 'vuex'
import SelectInput from '@/components/02-molecule/formElements/select'
import jwtDecode from 'jwt-decode'

export default {
  components: { SelectInput },
  data: () => ({
    data: '',
    filters: {
      options: [{
        name: 'Todas',
        value: null
      }]
    },
    patients: [],
    patientsNot: [],
    patientsNotTotal: [],
    currentFilters: {
      emotions: {
        name: 'Todas',
        value: null
      }
    },
    statistics: null
  }),
  computed: {
    ...mapState({
      S_INSTITUTIONS: state => state.S_INSTITUTIONS,
      S_INSCRITOS: state => state.Statistics.S_INSCRITOS,
      S_NO_INSCRITOS: state => state.Statistics.S_NO_INSCRITOS
    }),
    userLocalData () {
      return jwtDecode(this.$cookies.get('AUTH_TOKEN')).user
    }
  },
  async created () {
    const { idInstitution } = this.userLocalData
    this.statistics = await this.A_GET_STATISTICS({ idInstitution })
    console.log('ESTADISTICAS : ', this.statistics)
    const options = await this.A_GET_INSTITUTIONS({ type: 2 })
    options.map(item => {
      this.filters.options.push({
        name: item.name,
        value: item.id
      })
    })
    await this.A_GET_INSCRITOS({
      idInstitution
    })
    await this.A_GET_NO_INSCRITOS({
      idInstitution
    })
    console.log('Inscritos del chamo', this.S_INSCRITOS)
    console.log('No Inscritos del chamo', this.S_NO_INSCRITOS)
  },
  methods: {
    ...mapActions({
      A_GET_STATISTICS: 'Statistics/A_GET_STATISTICS',
      A_GET_INSCRITOS: 'Statistics/A_GET_INSCRITOS',
      A_GET_NO_INSCRITOS: 'Statistics/A_GET_NO_INSCRITOS',
      A_GET_INSTITUTIONS: 'Institution/A_GET_INSTITUTIONS'
    }),
    changeCurrentEmotion (e) {
      this.currentFilters.emotions = e
    }
  }
}
</script>
<style lang='sass' scoped>
</style>
