<template lang="pug">
  validation-provider(
    :slim="true"
    :rules="''"
    v-slot="{ errors }"
  )
    .c-select(
      :class="[inputStyle]"
    )
      .c-select__label(
        @click="stateSelect = !stateSelect"
        v-click-outside="hide"
      )
        span {{ currentElement.name }}
        i.icon-down
      transition(name="fade")
        ul.c-select__options(v-show="stateSelect")
          li(
            v-for="element in elements"
            @click="currentElement = element"
          ) {{ element.name }}
</template>

<script>

export default {
  components: {
    // component
  },
  props: {
    // propierty: { required: false, type: String },
    elements: {
      required: true,
      type: Array
    },
    placeholder: {
      required: false,
      type: Object
    },
    inputStyle: {
      required: false,
      type: String
    }
  },

  data () {
    return {
      data: '',
      stateSelect: false,
      currentElement: null
    }
  },

  computed: {
    // ...mapState({
    // homeCampaign: state => state.homeCampaign
    // })
  },

  watch: {
    currentElement () {
      this.$emit('input', this.currentElement)
      this.stateSelect = false
    }
  },
  created () {
    if (this.placeholder) {
      this.currentElement = this.placeholder
    } else {
      this.currentElement = this.elements[0]
    }
  },
  mounted () {},
  updated () {},
  methods: {
    hide () {
      this.stateSelect = false
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
